import * as React from 'react'
import Layout from '/src/components/layout'

const VillkorPage = () => {
    return (
        <Layout pageTitle="Villkor">

            <h2>Användarvillkor</h2>
            <p>Du är fri att använda laddify.se kostnadsfritt för att hitta laddstationer och information kring dessa. Inga garantier lämnas och Laddify reserverar sig för eventuella felaktigheter i informationen. Källorna för informationen kan inte hållas ansvariga för varken informationen eller Laddify. Du är inte tillåten att använda Laddify på andra sätt än tilltänkt eller att använda Laddifys API:er utan tillåtelse.</p>

            <h2>Personuppgiftspolicy</h2>
            <p>Laddify sparar inga personuppgifter om dig som besökare. Punkt. Av den enkla anledningen att det inte är rätt och behövs inte. Laddifys intäcktskälla är reklam och inte försäljning av besökares personuppgifter. Anonym statistik utan koppling till enskilda personer (i och med att den informationen inte finns) delas till tredje part genom publik statistik eller statistik till laddnätverk.</p>
            <p>Personuppgifter är inte tillåtet i incheckningar på laddstationer och modereras bort vid behov.</p>
            <p>Information som hämtas från laddnätverk kan innehålla personuppgifter i deras information, exempelvis kontaktperson till någon på laddnätverket. Alltså inte rörande besökare till Laddify.</p>

        </Layout>
    )
}

export default VillkorPage